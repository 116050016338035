<template>
  <v-container fluid fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm8 md3>
        <v-card class="elevation-12">
          <v-form ref="form" @submit.prevent="onSubmit">
            <v-card-text>
              <div style="min-height: 60px;">
                  <v-alert
                    class="alert-default"
                    transition="scale-transition"
                    type="error"
                    :value="alertError !== null"
                    >{{ alertError }}</v-alert
                  >
              </div>
              <v-text-field
                name="login"
                v-model="email"
                :label="$t('auth.username')"
                class="mx-2"
                type="email"
                :rules="rules.rulesEmail"
              ></v-text-field>
            </v-card-text>
            <v-card-text>
              <v-btn width="100%" type="submit" style="height: 50px" color="primary">{{ $t('buttons.login') }}</v-btn>
            </v-card-text>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { mapMutations, mapGetters } from 'vuex'
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import { isEmailValid } from '@/utils/validators.js'

export default {
  data () {
    return {
      // data:this.$store.user,
      email: '',
      tokenSave: '',
      rules: {
        rulesEmail: [
          v => !!v || '必須項目です。',
          v =>
            !v ||
            isEmailValid(v) ||
            this.$t('rules.emailIsInvalid')
        ]
      }
    }
  },
  methods: {
    getCopyErrorTextView,
    ...mapMutations([
      'setCopyErrorText',
      'setTokenAndEmail',
      'setAlertError'
    ]),
    // change password
    async onSubmit () {
      if (this.$refs.form.validate()) {
        const formData = {
          email: this.email
        }
        if (await this.reqResetpassword(formData)) {
          this.$router.push({ path: '/change-password' })
        } else {
          console.log(false)
        }
      }
    },

    async reqResetpassword (formData) {
      const query = `
            mutation initiateReset($email: String!){
              requestResetPasswordStaff(email: $email) {
                token
              }
            }
          `
      const variables = {
        email: formData.email
      }
      await this.$apollo
        .mutate({
          mutation: gql`${query}`,
          variables: variables
        })
        .then(data => {
          this.tokenSave = data.data.requestResetPasswordStaff.token
          this.setTokenAndEmail({
            email: formData.email,
            token: data.data.requestResetPasswordStaff.token
          })
        })
        .catch(async error => {
          this.setCopyErrorText(this.getCopyErrorTextView(query, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
        })
      if (this.tokenSave.length > 0) {
        return true
      } else {
        return false
      }
    },

    // confirm password
    checkPassword (value) {
      if (value.toString() === this.password.toString()) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    doneTodosCount (value) {
      if (value !== null) {
        setTimeout(() => {
          this.setAlertSuccess(null)
        }, 2000)
      }
    },
    doneTodosCountError (value) {
      if (value !== null) {
        setTimeout(() => {
          this.setAlertError(null)
        }, 2000)
      }
    }
  },
  computed: {
    ...mapGetters(['alertSuccess', 'alertError', 'errorDialog', 'loadingOverlay']),
    doneTodosCount () {
      return this.alertSuccess
    },
    doneTodosCountError () {
      return this.alertError
    }
  },
  mounted: async function () {
    if (this.$store.state.user.token) {
      await this.$router.push({ path: '/' })
    }
  }
}
</script>

<style scoped>
.v-form .v-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.v-card {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>
